@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "Montserrat", sans-serif;

  --primary-color: #005679;
  --secondary-color: #2f6386;
  --bg-color: #f6f4f1;

  --black-color: #030303;
  --lightGray-color: #e4e4e4;

  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
